.App {
  text-align: center;
}

.Navbar {
  width: 100vw;
  background-color: rgb(33, 106, 241);
  font-size: 2.75rem;
  margin: 0;
  padding: 15px 0px;
  position: fixed;
  top: 0;
  z-index: 1000;
  font-weight: 500;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .4);
}

.container {
  width: 100%;
  padding-top: 7rem;
  background-color: rgb(182, 182, 182);
  min-height: calc(100vh - 7rem);
  /*Flexbox related below*/
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.body-block {
  background-color: rgb(129, 129, 129);
  font-size: 1rem;
  max-width: 500px;
  width: 85%;
  min-height: 150px;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
}

.players {
  margin: 15px;
}

.LeaderboardPlayer {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
}

.LeaderboardPlayer>div {
  width: 20%;
  margin: 5px;
  font-size: 1.2rem;
}

.body-header {
  font-weight: 400;
  font-size: 1.9rem;
}

.player-teams {
  margin: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.player-team {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.team {
  width: 40%;
  font-size: .85rem;
}

.points {
  width: 15%;
}

.spinner {
  width: 5rem;
  margin-top: 10rem;
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}